@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@angular/cdk/overlay-prebuilt.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/dist/svg-arrow.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

@layer base {
  :root {
    --theme-brand: #3b82f6;
    --theme-darkest: #0F172A;
    --theme-darker: #1E293B;
    --theme-dark: #304065;
    --theme-mid: #426AC6;
    --theme-light: #CBD5E1;
    --theme-lighter: #F5F5F5;
    --theme-lightest: #FFF;

    --theme-brand-transparent: rgba(59, 130, 246, 0.5);
  }

  body {
    @apply text-darkest;
    background: #F0F6FE;
    height: 100svh;
    }

  .full-height {
    height: 100svh;
  }

  .max-full-height {
    max-height: 100svh;
  }

  .blurred-bg {
    @apply absolute inset-0 bg-gradient-to-b from-transparent to-light backdrop-filter backdrop-blur-sm;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer components {
  .button-primary {

    &:not(.no-padding) {
      @apply py-2 px-4
    }

    &:not(:disabled) {
      @apply bg-brand text-lightest font-medium rounded focus:outline-none active:outline-none ring-0 transition-all;
    }

    &:disabled, &.disabled {
      @apply bg-gray-200 text-gray-400 font-medium rounded focus:outline-none active:outline-none cursor-not-allowed;
    }

    &:focus, &:active {
      &:not(:disabled) {
        @apply ring-[0.125rem] ring-brand-transparent;
      }
    }
  }

  .button-secondary {
    &:not(:disabled) {
      @apply bg-lighter text-brand font-medium py-2 px-4 rounded focus:outline-none active:outline-none ring-0 transition-all;
    }

    &:disabled {
      @apply bg-gray-200 text-gray-400 font-medium py-2 px-4 rounded focus:outline-none active:outline-none cursor-not-allowed;
    }

    &:focus, &:active {
      &:not(:disabled) {
        @apply ring-[0.125rem] ring-brand-transparent;
      }
    }
  }

  .button-danger {
    &:not(:disabled) {
      @apply bg-red-500 text-lightest font-medium py-2 px-4 rounded focus:outline-none active:outline-none ring-0 transition-all;
    }

    &:disabled {
      @apply bg-red-200 text-red-400 font-medium py-2 px-4 rounded focus:outline-none active:outline-none cursor-not-allowed;
    }

    &:focus, &:active {
      &:not(:disabled) {
        @apply ring-[0.125rem] ring-red-500/50;
      }
  }
  }

  .input {
    @apply bg-lightest border border-light rounded focus:outline-none active:outline-none ring-0 transition-shadow;

    &.ng-invalid:not(.ng-pristine):not(:focus) {
      @apply border-red-500 text-red-800 placeholder:text-red-300;
    }

    &:has(*.ng-invalid:not(.ng-pristine):not(:focus)) {
      @apply border-red-500 text-red-800 placeholder:text-red-300;
    }

    &[type="checkbox"] {
      // todo: inconsistent use of `@apply` here
      display: inline-grid;
      place-content: center;
      appearance: none;
      background-color: #fff;
      width: 16px;
      height: 16px;
      border: 1px solid var(--theme-brand);
      border-radius: 2px;
      padding: 0.5rem;

      &::before {
          content: "";
          width: 12px;
          height: 12px;
          opacity: 0;
          box-shadow: inset 12px 12px var(--theme-brand);
          border-radius: 1px;
          transition: opacity 0.05s ease-in-out;
      }

      &:checked::before {
          opacity: 1;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:focus, &:active, &:focus-within {
      &:not(:disabled) {
        @apply ring-[0.125rem] ring-brand-transparent;
      }
    }
  }

  .default-ring {
    @apply ring-brand focus-visible:ring-2 focus:outline-none;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.tippy-box[data-theme~='tour'] {
  background-color: transparent;
  margin-left: -8px;
  .tippy-content {
    padding: 0;
  }
}

.tippy-box[data-theme~='tour'] .tippy-arrow,
.tippy-box[data-theme~='tour'] .tippy-svg-arrow {
  color: var(--theme-lightest);
  fill: var(--theme-lightest);
}
